<template>
  <Card style="width: 40rem; margin-bottom: 2em">
    <template #content>
      <div v-if="prediction.scenario_id == 1">
        <!-- <h2>Значительный размер веществ, но судимости нет</h2> -->
        <h2>{{ prediction.punishment_text }}: вероятность {{ confidence }}%</h2>
        <h3 class="sorry">Но это не точно, предсказатель еще учится</h3>

        <h4>Рекомендации:</h4>
        <p>
          По части 1 статьи 228 УК, суд может прекратить уголовное дело и
          назначить судебный штраф. Для этого подсудимый должен загладить ущерб,
          причиненный преступлением. Почитайте инструкцию
          <a href="https://hand-help.ru/pamyatki/sudebnyij-shtraf-instruktsiya"
            >«Как прекратить уголовное дело за хранение наркотиков и получить
            судебный штраф?».</a
          >
        </p>
      </div>
      <div v-if="prediction.scenario_id == 2">
        <!--<h2>Значительный размер веществ, но судимость есть</h2>-->
        <h2>{{ prediction.punishment_text }}: вероятность {{ confidence }}%</h2>
        <h3 class="sorry">Но это не точно, предсказатель еще учится</h3>
        <h4>Рекомендации:</h4>
        <ul>
          <li>
            Уголовное наказание можно заменить
            <a href="https://hand-help.ru/pamyatki/zamena-nakazaniya-lecheniem"
              >лечением или реабилитацией.</a
            >
          </li>
          <li>
            О правилах назначения наказания, смягчающих вину обстоятельствах и
            других аспектах уголовного дела смотрите в
            <a href="https://guide228.hand-help.ru/#228"
              >«Путеводителе по законодательству и судебной практике для
              обвиняемых и осужденных за наркотики»</a
            >.
          </li>
        </ul>
      </div>

      <div v-if="prediction.scenario_id == 3">
        <!--<h2>Крупный размер изъятого вещества</h2>-->
        <h2>{{ prediction.punishment_text }}: вероятность {{ confidence }}%</h2>
        <h3 class="sorry">Но это не точно, предсказатель еще учится</h3>
        <h4>Рекомендации:</h4>
        <p>
          Уголовное наказание можно заменить лечением или реабилитацией. О
          правилах назначения наказания, смягчающих вину обстоятельствах и
          других аспектах уголовного дела смотрите в Путеводителе по
          законодательству и судебной практике для обвиняемых и осужденных за
          наркотики.
        </p>
      </div>
      <div v-if="prediction.scenario_id == 4">
        <!-- <h2>Особо крупный размер вещества</h2>-->
        <h2>{{ prediction.punishment_text }}: вероятность {{ confidence }}%</h2>
        <h3 class="sorry">Но это не точно, предсказатель еще учится</h3>
        <h4>Рекомендации:</h4>
        <p>
          Уголовное наказание можно заменить лечением или реабилитацией. О
          правилах назначения наказания, смягчающих вину обстоятельствах и
          других аспектах уголовного дела смотрите в Путеводителе по
          законодательству и судебной практике для обвиняемых и осужденных за
          наркотики.
        </p>
      </div>
      <div v-if="prediction.scenario_id == 5">
        <h2>Менее значительного размера</h2>
        <p>
          <b>Приобретение и хранение</b> такого количества наркотиков не
          считается уголовным преступлением (<a
            href="https://hand-help.ru/zakonodatelstvo/postanovleniya/sizesofdrugs2013"
            >по постановлению Правительства от 01.10.2012 г. № 1002</a
          >).
        </p>
        <p>
          Это административное правонарушение. Наказание — штраф до 4 до 5 тыс.
          руб. или арест на срок до 15 суток (статья 6.8 Кодекса об
          административных правонарушениях).
        </p>
        <p>
          Употребление наркотиков влечет такую же ответственность (по статье 6.9
          КоАП).
        </p>
        <p>
          «Предсказатель» еще не изучил постановления судов по этой категории
          дел.
        </p>
        <p>
          Согласно данным общероссийской судебной статистики, в ___ % дел
          назначается штраф, а в ____ % дел — арест. В ____ % суд обязует
          граждан пройти диагностику, профилактику и лечение в наркодиспансере
          по месту жительства.
        </p>
        <h4>Рекомендации:</h4>
        <p>
          Посмотреть консультации по данной категории дела в разделе
          «административные дела».
        </p>
      </div>
    </template>
    tem
  </Card>
</template>

<script>
// import Panel from "primevue/panel";
import Card from "primevue/card";

export default {
  components: { Card },
  props: { prediction: {}, display: Boolean },
  data() {
    return {
      punisment_text: String,
      scenario_id: String,
      confidence: Number,
    };
  },
  mounted() {
    console.log(this.prediction);
    this.confidence = this.prediction.confidence * 100;
    this.confidence = this.confidence.toFixed(2);
  },
  //   computed: {
  //     getType() {
  //       return this.prediction.type;
  //     },
  //   },
};
</script>

<style scoped>
.p-panel-header {
  font-size: 30px;
}
.sorry {
  color: red;
}
</style>
